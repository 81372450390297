import axios, { AxiosResponse } from "axios";
import APP_CONSTANTS from "../constants";
import { ICreateAppointment } from "../interfaces/Appointment";
import {
  IConsultation,
  ICreateConsultation,
  IMedicalHistory,
  IUpdateConsultation,
} from "../interfaces/Consultation";
import { toast } from "react-hot-toast";
import { generateUniqueId } from "../services/utils.service";
import { clearLocalStorageItemsWithPrefix } from "../utils";
import { INotificationBody } from "../interfaces/notifications";
import { PATIENT_FILTER } from "../enums/table";
import { waitingListStatus } from "../enums/waitingList";
import { appointmentStatus } from "../enums/appointment";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + "/",
  headers: {
    "ngrok-skip-browser-warning": "any",
  },
});

const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`
    );

    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/auth/refreshToken`,
      {
        refreshToken: refreshToken,
      }
    );

    const newAccessToken = res.data.accessToken;
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`,
      newAccessToken
    );

    const newRefreshToken = res.data.refreshToken;
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`,
      newRefreshToken
    );
    return newAccessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
  );
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  config.headers.ContentType = "multipart/form-data";
  return config;
});

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     // test notif

//     const originalRequest = error.config;

//     try {
//       if (error.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         const newAccessToken = await refreshToken();
//         originalRequest.headers.Authorization = "Bearer " + newAccessToken;
//         return instance(originalRequest);
//       }
//       if (error.response && error.response.status === 400) {
//         // toast.error('Bad request. Please check your input.');
//       }
//     } catch (err: any) {
//       return Promise.reject(error);
//     }

//     return Promise.reject(error);
//   }
// );
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const errorStatus = error?.response?.data?.statusCode;
    const errorMessage = error?.response?.data?.message;
    if (errorStatus == "409") {
      toast.error(
        `Le champs ${errorMessage.join(
          ","
        )} est déja existant dans la base des données.`,
        {
          id: "900",
        }
      );
      return false;
    }
    if (errorStatus == "401") {
      clearLocalStorageItemsWithPrefix(APP_CONSTANTS.LOCALSTORAGE_PREFIX);
      toast.error(`Session expirée`, {
        id: "110",
      });
      window.location.pathname = "/login";

      return false;
    }
    // toast.error("une erreur est survenue", {
    //   id: generateUniqueId()
    // });
    else return Promise.reject(error);
  }
);
export const getLocation = async (state: string, str: any) => {
  if (str) {
    return await instance.get(state);
  }
};

export const getAIIframeLink = async (data: any) => {
  return await instance.post(
    `https://services.aba.technology/public/services/symptomator/getlink`,
    data
  );
};

//SignOut
export const SignOut = async (data: any) => {
  return await instance.post("/auth/signOut", data);
};
//dispo doctor
export const patchDisponibilite = async (id: number, data: any) => {
  return await instance.patch(`/doctor/${id}`, data);
};

// get doctor name by id

export const getDoctorNamebyId = async (id: number) => {
  try {
    if (id) {
      let res = await instance.get(`/doctor/${id}`, {
        params: {
          include: JSON.stringify({
            user: true,
            speciality: true,
          }),
        },
      } as any);
      return res.data;
    }
  } catch (error) {
    // console.log(error);
  }
};
// get doctor by id

export const getDoctorById = async (id: number) => {
  return await instance.get(`/doctor/${id}?include=user.credential`);
};
export const getStaffById = async (id: number) => {
  return await instance.get(`/staff/${id}?include=user.credential`);
};
// update doctor

export const updateDoctor = async (docId: any, values: any) => {
  return await instance.patch(`/doctor/${docId}`, values);
};

/**
 *
 * @param id
 * @param values
 * @returns
 */
export const updateStaff = async (id: any, values: any) => {
  return await instance.patch(`/staff/${id}`, values);
};

// getDotorSlots
export const getDoctorSlots = async (id: number) => {
  try {
    let res = await instance.get(`/doctor/days/${id}`, {
      params: {
        include: JSON.stringify({
          user: true,
          speciality: true,
        }),
      },
    } as any);
    return res.data;
  } catch (error) {
    // console.log(error);
  }
};

/**
 *
 * @param options filter options the default are take and skip
 * @param str test to search for
 * @returns
 */
export const searchDoctorData = async (
  options: { skip: number; take: number },
  docSpecialtyId: number,
  str?: string
) => {
  const { skip, take = 5 } = options;
  const query =
    str && docSpecialtyId
      ? `search=${str}&include=user&skip=${skip}&take=${take}&where={"speciality":{"id":${docSpecialtyId}}}`
      : str
      ? `search=${str}&include=user&skip=${skip}&take=${take}`
      : `skip=${skip}&take=${take}&include=user`;

  return await instance.get(`/doctor?${query}`);
};

/**
 *
 * @param id
 * @returns
 */
export const getPatientNamebyId = async (id: number) => {
  try {
    let res = await instance.get(`/patient/${id}`, {
      params: {
        include: JSON.stringify({
          user: true,
        }),
      },
    } as any);
    return res.data;
  } catch (error) {
    // console.log(error);
  }
};

export const AuthLogin = async (login: string, password: string) => {
  try {
    // console.log("login user...");
    let res = await instance.post("auth/login?include=user", {
      login: login,
      password: password,
    });
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`,
      res.data.tokens.accessToken
    );
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`,
      res.data.tokens.refreshToken
    );
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}role`,
      res.data.role
    );
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`,
      res.data.data.userId
    );
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`,
      res.data.data.user.doctor.id
    );
    return res;
  } catch (e) {
    return false;
  }
};

/**
 *
 * @param data
 * @returns
 */
const formatPatientData = (data: any) => {
  let user: any = {};
  let values: any = {};

  delete data.provinceId;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (
        key === "firstName" ||
        key === "lastName" ||
        key === "originName" ||
        key === "gender" ||
        key === "email" ||
        key === "address" ||
        key === "address2" ||
        key === "postalCode" ||
        key === "phoneNumber" ||
        key === "phoneNumber2" ||
        key === "language" ||
        key === "birthDate"
      )
        user[key] = data[key];
      else if (key == "cityId")
        values["city"] = { id: parseInt(data[key], 10) };
      else if (key == "centerId")
        values["center"] = { id: parseInt(data[key], 10) };
      else if (key !== "country" && key !== "province" && key !== "city")
        values[key] = data[key]?.toString();
    }
  }
  values["user"] = user;
  // values["city"] = {
  //   id: 1,
  // };
  return values;
};

/**
 *
 * @param data
 * @param isSearchAndAdd
 * @param isChild
 * @param isWife
 * @param patientCine
 * @returns
 */
export const addPatient = async (
  data: any,
  isSearchAndAdd: boolean | undefined,
  isChild?: boolean | undefined,
  isWife?: boolean | undefined,
  patientCine?: string | undefined
) => {
  if (!isSearchAndAdd) {
    let values: any = formatPatientData(data.data);
    // Li dar hadi ghayakoul l3sa ->
    // if (values.user.gender == "Masculin") values.user.gender = "male";
    // else values.user.gender = "female";

    return await instance.post("/patient", values);
  } else {
    let values: any = formatPatientData(data.data.dataToAdd);
    let member: any = formatPatientData(data.data.values);
    if (isChild) values["type"] = "child";
    else if (isWife) values["type"] = "spouse";
    if (!data?.data?.values?.gender) {
      values.user["gender"] = "male";
    }

    values["member"] = { cine: patientCine };
    if (data.data.values.cine) values.cine = data.data.values.cine;

    // values.cine = data.data.values.cine;

    values.user = { ...values.user, ...member.user };
    try {
      return await instance.post("/patient", values);
    } catch (error) {
      // console.log(error);
    }
  }
};

/**
 *
 * @param data
 * @param isSearchAndAdd
 * @param patientData
 * @returns
 */

export const updatePatient = async (
  data: any,
  isSearchAndAdd: boolean | undefined,
  // isChild?: boolean | undefined,
  // isWife?: boolean | undefined,
  // patientCine?: string | undefined,
  patientData?: any
) => {
  if (!isSearchAndAdd) {
    let values: any = formatPatientData(data.data);
    // if (values.user.gender == "Masculin") values.user.gender = "male";
    // else values.user.gender = "female";
    try {
      return await instance.patch(`/patient/${patientData?.id}`, values);

      // console.log(res);
    } catch (error) {}
  } else {
    //  let values: any = formatPatientData(data.data.dataToAdd);
    //  let member: any = formatPatientData(data.data.values);
    //  console.log("dataToADD", data.data.dataToAdd);
    //  console.log("mememenrt", data.data.values);
    // if (isChild) values["type"] = "child";
    // else if (isWife) values["type"] = "spouse";
    // if (!data.data.values.gender) {
    //   values.user["gender"] = "male";
    // }
    // values["member"] = { cine: patientCine };
    // if (data.data.values.cine) values.cine = data.data.values.cine;
    // // values.cine = data.data.values.cine;
    // values.user = { ...values.user, ...member.user };
    // console.log("valuesss", values);
    // try {
    //   console.log("posted", values);
    //   return await instance.post("/patient", values);
    // } catch (error) {
    //   console.log(error);
    // }
  }
};

/**
 *
 * @param id
 * @param query
 * @returns
 */
export const getPatient = async (id: number, query?: string) => {
  // console.log("getting patient again", id);
  return query
    ? await instance.get(`/patient/${id}?include=user&${query}`)
    : await instance.get(`/patient/${id}?include=user`);
};

/**
 *
 * @param centerId
 * @returns
 */
export const getDoctorByCenterId = async (centerId: number) => {
  return instance.get(`doctor?where={"user":{"centerId":${centerId}}}`);
};
/**
 *
 * @param id
 * @returns
 */
export const getMedicalHistory = async (id: number) => {
  return await instance
    .get(`/consultation/${id}?include=patient.medicalHistory`)
    .then((result) => console.log(result));
};
/**
 *
 * @param id
 * @returns
 */
export const getMedicationHistory = async (id: number) => {
  return await instance.get(
    `/consultation/${id}?include=patient.medicationHistory.medication`
  );
};

/**
 *
 * @param id
 * @returns
 */

export const getMedicationHistoryByPatientId = async (id: number) => {
  return await instance.get(
    `/patient/${id}?include=medicationHistory.medication`
  );
};

/**
 * ENDPOINT TO GET MEDICATION HISTORY INCLUDING PRESCRIPTIONS
 * @param id
 * @returns
 */

export const getMedicationHistoryWithActualPrescriptions = async (
  id: number
) => {
  return await instance.get(`/patient/medicationHistory/${id}`);
};

/**
 *
 * @param id
 * @returns
 */
export const getWaitingListByCenterId = async (id: number) => {
  return await instance.get(
    `/admission?take=10000&where={"centerId":${id},"status":"waiting"}&include=patient.user,speciality`
  );
};

/**
 *
 * @param param0
 * @returns
 */
export const addPatientToWaitingList = async ({
  patientId,
  centerId,
  specialityId,
}: any) => {
  return await instance.post(`/admission`, {
    patientId,
    centerId,
    specialityId,
    status: waitingListStatus.WAITING,
  });
};

/**
 *
 * @param admissionId
 * @returns
 */
export const admitPatient = async (admissionId: any) => {
  return await instance.patch(`/admission/${admissionId}`, {
    status: waitingListStatus.IN,
  });
};

/**
 *
 * @param param0
 * @returns
 */
export const deletePatientFromWaitingList = async (admissionId: number) => {
  return await instance.patch(`/admission/${admissionId}`, {
    status: waitingListStatus.CANCELLED,
  });
};

export const getChiefComplaint = async (id: number) => {
  return await instance.get(
    `/consultation/${id}?include=chiefComplaintConsultations.chiefComplaint`
  );
};

/**
 *
 * @param options
 * @returns
 */
export const searchPatientData = async (options: any, str?: string) => {
  const { skip, take = 5, patientFilter, dateRange = [null, null] } = options;
  const doctorId = parseInt(
    localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`) || "",
    10
  );
  const stationId = JSON.parse(
    localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}box`) || "{}"
  ).id;
  const centerId = JSON.parse(
    localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}center`) || "{}"
  ).id;
  // ////////////////

  let whereClause = {};
  let dateFilterAllPatients = {};
  let dateFilterConsultation = {};
  let q = 'orderBy={"updatedAt":"desc"}&include=user';

  // filtering date
  if (dateRange && dateRange.length > 0) {
    if (dateRange[0] && dateRange[1]) {
      const from = dateRange[0] + "T00:00:00.0Z";
      const to = dateRange[1] + "T23:59:59.0Z";
      dateFilterAllPatients = {
        ...dateFilterAllPatients,
        AND: [{ updatedAt: { gte: from } }, { updatedAt: { lte: to } }],
      };
      dateFilterConsultation = {
        ...dateFilterConsultation,
        AND: [{ updatedAt: { gte: from } }, { updatedAt: { lte: to } }],
      };
    } else if (dateRange[0]) {
      const from = dateRange[0] + "T00:00:00.0Z";
      dateFilterAllPatients = {
        ...dateFilterAllPatients,
        AND: [{ updatedAt: { gte: from } }],
      };
      dateFilterConsultation = {
        ...dateFilterConsultation,
        AND: [{ updatedAt: { gte: from } }],
      };
    } else if (dateRange[1]) {
      const to = dateRange[1] + "T23:59:59.0Z";
      dateFilterAllPatients = {
        ...dateFilterAllPatients,
        AND: [{ updatedAt: { lte: to } }],
      };
      dateFilterConsultation = {
        ...dateFilterConsultation,
        AND: [{ updatedAt: { lte: to } }],
      };
    }
  }

  // making the constraints
  // filtering patients
  if (patientFilter == PATIENT_FILTER.ALL_PATIENTS)
    whereClause = { ...whereClause, ...dateFilterAllPatients };
  if (patientFilter == PATIENT_FILTER.CENTER_PATIENTS)
    whereClause = {
      ...whereClause,
      consultations: {
        some: { station: { centerId: centerId }, ...dateFilterConsultation },
      },
    };
  if (patientFilter == PATIENT_FILTER.DOCTOR_PATIENTS)
    whereClause = {
      ...whereClause,
      consultations: {
        some: { doctorId: doctorId, ...dateFilterConsultation },
      },
    };
  else if (patientFilter == PATIENT_FILTER.CURRENT_BOX)
    whereClause = {
      ...whereClause,
      consultations: { some: { stationId, ...dateFilterConsultation } },
    };

  if (take) q += `&take=${take}`;
  if (skip) q += `&skip=${skip}`;
  if (str) q += `&search=${str}`;

  if (Object.keys(whereClause).length > 0)
    q += `&where=${JSON.stringify(whereClause)}`;
  return await instance.get(`/patient?${q}`);

  //////////////////

  // if (patientFilter == PATIENT_FILTER.ALL_PATIENTS) {
  //   const query = str
  //     ? `orderBy={"updatedAt":"desc"}&search=${str}&include=user&skip=${skip}&take=${take}`
  //     : `orderBy={"updatedAt":"desc"}&skip=${skip}&take=${take}&include=user`;

  //   return await instance.get(`/patient?${query}`);
  // }
  // else if (patientFilter == PATIENT_FILTER.DOCTOR_PATIENTS) {
  //   const query = str
  //     ? `orderBy={"updatedAt":"desc"}&where={""}&search=${str}&include=user&skip=${skip}&take=${take}`
  //     : `orderBy={"updatedAt":"desc"}&where={""}&skip=${skip}&take=${take}&include=user`;

  //   return await instance.get(`/patient?${query}`);
  // }
};

/**
 *
 * @param updatedConsultation
 * @param id
 * @returns
 */
export const updateConsultation = async (
  updatedConsultation: IUpdateConsultation,
  id: number
): Promise<AxiosResponse<IConsultation>> => {
  delete updatedConsultation?.id;
  delete updatedConsultation?.createdAt;
  delete updatedConsultation?.updatedAt;
  delete updatedConsultation?.deletedAt;
  return await instance.patch(`/consultation/${id}`, updatedConsultation);
};

export const notifySpecilist = async (notificationBody: INotificationBody) => {
  return await instance.post(`/notification`, notificationBody);
};

export const changeStatusAppointment = async (id: number, status: string) => {
  return await instance.patch(`/appointment/${id}`, { status });
};

/**
 *
 * @param str
 * @returns
 */
export const getPatientByStr = async (str?: string) => {
  const query = str ? `search=${str}&include=user` : `include=user`;
  return await instance.get(`/patient?${query}`);
};

/**
 *
 * @param appointment
 * @returns
 */
export const addAppointment = async (appointment: ICreateAppointment) => {
  return await instance.post(`/appointment`, appointment);
};

/**
 *
 * @returns
 */
export const getAppointments = async (options: any, str?: string) => {
  const { skip, take = 5 } = options;
  return await instance.get(`/appointment?&skip=${skip}&take=${take}`);
};



export const getAppointmentsBySpecialty = async (specId: number) => {
  return await instance.get(
    `/appointment?take=10000&include=patient.user,doctor.speciality&where={"doctor":{"speciality":{"id":${specId}}}}`
  );
};

export const getAppointmentsByPatientId: any = async (patientId: number) => {
  return await instance.get(`/appointment?where={"patientId":${patientId}}`);
};

/**
 * The function bellow is to get the appointments of a doctor specialist
 * @param doctorId
 * @param options
 * @param str
 * @returns
 */
export const getAppointmentsByDoctorId: any = async (
  doctorId: number,
  options = { skip: 0, take: 1000 },
  str?: string
) => {
  const { skip, take } = options;
  return str
    ? await instance.get(
        `/appointment?take=10000&where={"doctorId":${doctorId},"status":{"notIn":["${appointmentStatus.CANCELLED}"]}}&include=patient.user,station.center&skip=${skip}&take=${take}`
      )
    : await instance.get(
        `/appointment?take=10000&where={"doctorId":${doctorId},"status":{"notIn":["${appointmentStatus.CANCELLED}"]}}&include=station.center,patient.user`
      );
};
/**
 * The function bellow is to get appointment by center
 * @param centerId
 * @returns
 */
export const getAppointmentsByCenterId: any = async (centerId: number) => {
  return await instance.get(
    `/appointment?take=10000&where={"centerId":${centerId},"status":{"notIn":["${appointmentStatus.CANCELLED}"]}}&include={"patient":{"include":{"user":true}},"station":{"include":{"center":true}},"doctor":{"include":{"speciality":true, "user":true}}}`
  );
};

/**
 *
 * @returns
 */
export const getUnits: any = async () => {
  return await instance.get(`/center?include=stations.user`);
};

/**
 *
 * @returns
 */
export const getBoxes: any = async (unitId: number) => {
  return await instance.get(
    `/station?where={"gender":"station","centerId":${unitId}}`
  );
};

/**
 *
 * @param id
 * @param status
 * @returns
 */
export const cancelAppointment = async (id: number, status: string) => {
  return await instance.patch(`/appointment/${id}`, { status });
};
/**
 *
 * @param consultation
 * @returns
 */
export const createConsultation = async (consultation: ICreateConsultation) => {
  return await instance.post(`/consultation`, consultation);
};

export const getConsultation = async (
  consultationId: number,
  query?: string
) => {
  return query
    ? await instance.get(`/consultation/${consultationId}?${query}`)
    : ((await instance.get(
        `/consultation/${consultationId}?include=medicalHistory`
      )) as AxiosResponse<IConsultation>);
};

export const getConsultationByDoctorID = async (
  doctorId: number,
  query?: string
) => {
  return await instance.get(
    `/consultation/?where={"doctorId":${doctorId}}&include=patient.user`
  );
};

/**
 *
 * @param str
 * @returns
 */
export const getMedications = async (str?: string) => {
  return str
    ? await instance.get(`/medication?search=${str}&skip=${0}&take=${20}`)
    : await instance.get(`/medication?&skip=${0}&take=${20}`);
};
export const getMedicationsByIndex = async (id: number) => {
  return await instance.get(`/medication/${id}`);
};

/**
 *
 * @param str
 * @returns
 */
export const getChiefComplaints = async (str: string) => {
  return str
    ? await instance.get(
        `/chiefComplaint?where={"type":"category"}&search=${str}&skip=${0}&take=${20}`
      )
    : await instance.get(
        `/chiefComplaint?where={"type":"category"}&skip=${0}&take=${20}`
      );
};
/**
 *
 * @param
 * @returns
 */
export const getHospitals = async (
  options: { skip: number; take: number },
  str?: string
) => {
  const { skip, take = 5 } = options;
  return str
    ? await instance.get(`/hospital?search=${str}&skip=${skip}&take=${take}`)
    : await instance.get(`/hospital?&skip=${skip}&take=${take}`);
};
/**
 *
 * @param
 * @returns
 */
export const getStations = async (id: number) => {
  return await instance.get(
    `/station/?include=user&where={"user":{"centerId":${id}}}`
  );
};
export const getCallsHistoryByDoctorId = async (
  userId: number,
  option: { skip: number; take: number },
  str?: string
) => {
  const { skip, take } = option;
  return str
    ? await instance.get(
        `/call?include=station.center&where={"userId":${userId}}&search=${str}&skip=${skip}&take=${take}`
      )
    : await instance.get(
        `/call?&include=station.center&where={"userId":${userId}}&skip=${skip}&take=${take}`
      );
};
/**
 *
 * @param str
 * @returns
 */
export const getAnalysis = async (str: string) => {
  return str
    ? await instance.get(`/analyse?search=${str}&skip=${0}&take=${20}`)
    : await instance.get(`/analyse?&skip=${0}&take=${20}`);
};
export const getAnalysisByIndex = async (id: number) => {
  return await instance.get(`/analyse/${id}`);
};
/**
 *
 * @param str
 * @returns
 */
export const getImaging = async (str: string) => {
  return str
    ? await instance.get(`/imaging?search=${str}&skip=${0}&take=${20}`)
    : await instance.get(`/imaging?&skip=${0}&take=${20}`);
};
export const getImagingByIndex = async (id: number) => {
  return await instance.get(`/imaging/${id}`);
};
/**
 *
 * @param str
 * @returns
 */
export const getSpecialities = async (
  options?: { skip: number; take: number },
  str?: string
) => {
  return str && options
    ? await instance.get(
        `/speciality?search=${str}&skip=${options.skip}&take=${options.take}`
      )
    : options
    ? await instance.get(
        `/speciality?&skip=${options.skip}&take=${options.take}}`
      )
    : await instance.get(`/speciality`);
};

export const getRegions = async () => {
  return await instance.get(`/region`);
};

export const getProvinces = async () => {
  return await instance.get(`/province?take=400&orderBy={"name":"asc"}`);
};

export const generateDocuments = async (data: any) => {
  return axios.post(
    `https://services.aba.technology/public/services/pdf/all?token=hello`,
    data
  );
};
/**
 *
 * @returns
 */
export const getCities = async (regionId?: number) => {
  return regionId
    ? await instance.get(
        `/city/${regionId}?take=5000&include=province&orderBy={"name":"asc"}`
      )
    : await instance.get(
        `/city?take=5000&include=province&orderBy={"name":"asc"}`
      );
};

/**
 *
 * @returns
 */
export const getCountries = async () => {
  return await instance.get(`/country`);
};
/**
 *
 * @returns
 */
export const getCenters = async () => {
  return await instance.get(`/center`);
};
export default instance;
