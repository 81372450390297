import {
  Button,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Spinner,
} from "@material-tailwind/react";
import { useState, useEffect, useMemo } from "react";
import MyButton from "../common/Button";
import { ReactComponent as Add } from "../../assets/add.svg";
import { ReactComponent as Filter } from "../../assets/filter.svg";
// import { ReactComponent as FileJoint } from "../../assets/file.svg";
import { ReactComponent as Download } from "../../assets/download.svg";
import { ReactComponent as Pdf } from "../../assets/pdf.svg";
import { ReactComponent as Back } from "../../assets/back.svg";
import Rendu from "./components/Rendu";
import { Traitement } from "./components/Traitement";
import { Editor } from "react-draft-wysiwyg";
import { ReactComponent as JoinFileSvg } from "../../assets/joinFile.svg";
import { ReactComponent as DeleteSvg } from "../../assets/deleteSvg.svg";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import {
  EditorState,
  convertFromRaw,
  // ContentState,
  convertToRaw,
  // convertFromRaw,
} from "draft-js";
import Select from "react-select";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AsyncSelect from "react-select/async";

import { Formik, Field } from "formik";
import Medications from "./components/Medications";
import axios from "axios";
import { MyUISelect } from "../documents";
import {
  getConsultation,
  sendFileByConsultationId,
  getMedicationConsultation,
  getPatientMedicalHistory,
  getSpeciality,
  postConsutation,
  getConsultationById,
  getAttachmentById,
} from "../../axiosAPI/medical-history";
import { useQuery } from "react-query";
import moment from "moment";
import Empty from "../common/Empty";
import Loader from "../common/ConsultationLoader";
import { set } from "lodash";
import { useParams } from "react-router-dom";
import APP_CONSTANTS from "../../constants";
import TextArea from "antd/es/input/TextArea";
import {
  getMedicationHistory,
  getMedicationHistoryByPatientId,
  getMedicationHistoryWithActualPrescriptions,
  getPatient,
} from "../../axiosAPI/Network";
import { EXIT_MODES } from "../../enums/consultation";
import toast from "react-hot-toast";
import "./in.css";
import first from "../../assets/firstLogo.svg";
import seconde from "../../assets/secondLogo.svg";
interface OptionType {
  label: string;
  value: string;
}
type Iordonnance = {
  name: string;
  path: string;
  deleteFile?: (index: number) => void;
  index: number;
  deletable?: boolean;
};
const Ordonnance = ({
  name,
  deleteFile,
  index,
  deletable,
  path,
}: Iordonnance) => {
  const downloadFile = async (path: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL?.slice(
          0,
          process.env.REACT_APP_SERVER_URL.length - 3
        )}/${path}`
      );
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const fileName = path.substring(path.lastIndexOf("/") + 1);

      // Extract the filename from the path
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName; // Set the filename using the extracted value
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleDownloadClick = (path: string) => {
    downloadFile(path);
  };
  return (
    <div className="flex justify-between items-center bg-[#EFEFEF] w-[25rem] h-[3rem] rounded-md mx-5 px-3">
      <div className="flex gap-2">
        <Pdf />
        <div className="text-[#5E5E5E] flex justify-start items-center truncate w-72 ">
          {name}
        </div>
      </div>
      <div>
        {deletable ? (
          <DeleteSvg
            onClick={() => deleteFile && deleteFile(index)}
            width={20}
            // onClick={() => setSelectedFile("")}
            className="z-[100] cursor-pointer hover:scale-110 transition-all ease-in-out duration-200"
          />
        ) : (
          <Download
            className="cursor-pointer hover:scale-110 transition-all ease-in-out duration-200"
            onClick={() => {
              handleDownloadClick(path);
            }}
          />
        )}
      </div>
    </div>
  );
};

interface IReportProps {
  patientId?: number;
}
const Report = (props: IReportProps) => {
  // create editor with hello world text
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [medications, setMedications] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [inputData, setInputData] = useState<any>({});
  const [files, setFiles] = useState<any[]>([]);
  const [formDataArray, setFormDataArray] = useState<any[]>([]);
  const [editorRawContent, setEditorRawContent] = useState<any>({});
  const [update, setUpdate] = useState(false);
  const [overview, setOverview] = useState(false);
  const [item, setItem] = useState<any>({});
  const [medicationOverview, setMedicationOverview] = useState<any>([]);
  const [fileOverView, setFileOverView] = useState<any>([]);
  const [traitement, setTraitement] = useState<any>([]);
  const [isLoader, setIsLoader] = useState(false);
  const [filterValue, setFilterValue] = useState<any>("all");
  const [patientId, setPatientId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [specialities, setSpecialities] = useState([]);
  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [doctorId, setDoctorId] = useState("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [commentaire, setCommentaire] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [isLoadinge, setIsLoadinge] = useState<boolean>(false);
  const [msgSize, setMsgSize] = useState<boolean>(false);
  // const { data, isLoading, error, refetch } = useQuery(
  //   ["medicalHistory", filterValue, patientId],
  //   () => getConsultation(filterValue, +patientId, true)
  // );

  // useEffect(() => {
  //   refetch();
  // }, [update]);

  useEffect(() => {
    if (patientId) {
      getOngoingMedicationHistory(parseInt(patientId, 10));

      // getConsultationById(461).then(res => console.log(res))

      getConsultation(filterValue, +patientId, true)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {});

      getSpeciality()
        .then((resp) => {
          setSpecialities(resp);
        })
        .catch((err) => {});
    }
  }, [patientId]);

  useEffect(() => {
    if (data) setIsLoading(false);
  }, [data]);

  // const {
  //   data: specialities,
  //   isLoading: specialityLoading,
  //   error: specialityError,
  // } = useQuery("speciality", getSpeciality);

  // if (specialityLoading) return <div>Loading...</div>;
  // if (specialityError) return <div>An error has occurred</div>;
  const handleConfirm = () => {
    setConfirm(!confirm);
  };
  const handleConfirmOk = () => {
    setConfirm(!confirm);

    submitData();
    setOverview(false);
  };
  const [fileSizeError, setFileSizeError] = useState(false);
  const handleFileChange = (e: any) => {
    if (e.target.files[0].size > 5000000) {
      setFileSizeError(true);
      return;
    }
    setInputData({ ...inputData, file: e.target.files[0] });
    setFiles([...files, e.target.files[0]]);
    setFileSizeError(false);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", inputData.file);
    formData.append("type", inputData.type);
    formData.append("name", inputData.name);
    formData.append("comment", inputData.comment);
    formData.append("specialityId", inputData.specialityId);
    const newFormDataArray = [...formDataArray, formData];
    setFormDataArray(newFormDataArray);

    // Clear the form fields
    setInputData({
      specialityId: "",
      type: "",
      name: "",
      comment: "",
      file: "",
    });
    handleOpen();
  };
  const handleOpen = () => setOpen(!open);
  // const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);

    // Save editor data to backend
    const contentState = convertToRaw(state.getCurrentContent());
    setEditorRawContent(contentState);
    // console.log("content State", contentState);
  };
  const filterOptions = async (inputValue: string): Promise<OptionType[]> => {
    // console.log("inputValue", inputValue.trim().length);

    let search = inputValue.trim();
    let url =
      search.length > 0
        ? `${process.env.REACT_APP_SERVER_URL}/medication?search=${inputValue}`
        : `${process.env.REACT_APP_SERVER_URL}/medication`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",

          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = response.data;
      const options = data.results.map((result: any) => ({
        label: result.name,
        value: result.id,
      }));
      return options;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const submitData = () => {
    setIsLoader(true);
    const contentState = convertToRaw(editorState.getCurrentContent());
    postConsutation(
      {
        isLocal: true,
        patientId: parseInt(patientId),
        doctorId: parseInt(doctorId),
        conclusion: JSON.stringify(contentState),
        medicationConsultations: medications.map((medication: any) => ({
          medicationId: medication.medicament.value,
          startTime: medication.startDate,
          endTime: medication.endDate,
          route: medication.oral.value,
          note: medication.instruction,
        })),
      },
      formDataArray
    )
      .then((response) => {
        setIsLoader(false);
        setUpdate(!update);
        // console.log("response", response);
        // setRendu(response.data)
        setPage(1);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  const renduData = useMemo(() => {
    return data?.map((item: any) => ({
      speciality: item?.doctor?.speciality?.name,
      name: item.doctor?.user?.firstName + " " + item?.doctor?.user?.lastName,
      date: moment(item?.createdAt).format("DD/MM/YYYY à HH:mm"),
      conclusion: item.conclusion,
      specialist: item?.specialist,
      specialistConclusion: item?.specialistConclusion,
      item: item,
      firstDiagnostic:
        item?.chiefComplaintConsultations &&
        item?.chiefComplaintConsultations.length > 0
          ? item?.chiefComplaintConsultations[0]?.chiefComplaint.name
          : null,
      otherDiagnostics:
        item?.chiefComplaintConsultations &&
        item?.chiefComplaintConsultations.length > 1
          ? item?.chiefComplaintConsultations[1]?.chiefComplaint.name
          : null,
    }));
  }, [data]);

  useEffect(() => {
    if (overview) {
      // here we specifie the medications that we will display
      const med = item?.medicationConsultations?.map((medication: any) => ({
        name: medication.medication.name,
        noon: medication.noon,
        evening: medication.evening,
        morning: medication.morning,
        period: medication.period,
        startDate: moment(medication.startTime).format("DD/MM/YYYY"),
        endDate: moment(medication.endTime).format("DD/MM/YYYY"),
        oral: medication.route,
        instruction: medication.note,
      }));

      const attachements = item?.attachements?.map((attachement: any) => ({
        name: attachement.name,
        path: attachement.path,
      }));

      console.log("attachements are", attachements);

      setFileOverView(attachements);
      setMedicationOverview(med);

      // handola();
    }
  }, [overview]);

  const specialityOptions =
    specialities?.map((item: any) => ({
      value: item.id,
      label: item.name,
    })) ?? [];

  // add two objects to this array
  const deleteFile = (index: number) => {
    const newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
  };

  /**
   * RETRIEVE THE ONGOING TREATMENTS, INCLUDING HISTORICAL AND ALSO THE PRESCRIPTED MEDICATIONS
   * @param id
   */
  const getOngoingMedicationHistory = async (id: number) => {
    const medicationHistoryWithTreatmentsResponse =
      await getMedicationHistoryWithActualPrescriptions(id);
    // const res = await getMedicationHistoryByPatientId(id);

    // historical medications
    const medicationHistory =
      medicationHistoryWithTreatmentsResponse.data.medicationHistory;

    // Actual prescriptions from the consultation
    const actualPrescriptions =
      medicationHistoryWithTreatmentsResponse.data.actualPrescription;

    const historicalData = [...medicationHistory, ...actualPrescriptions];

    // Filtering the on going treatments
    const onGoingTreatments = historicalData?.filter((medicalHistory: any) => {
      const endTimeMoment = moment(medicalHistory?.endTime);
      const currentDateMoment = moment();

      // Compare only the day, month, and year parts
      return (
        endTimeMoment.isAfter(currentDateMoment, "day") ||
        medicalHistory?.endTime == null
      );
    });
    setTraitement(onGoingTreatments ? onGoingTreatments : []);
  };
  useEffect(() => {
    getPatient(parseInt(patientId ? patientId : "", 10)).then(
      (response: any) => {
        console.log(response);
        console.log(patientId);
      }
    );
  }, [page]);

  const { id } = useParams();
  const getPatientId = () => {
    if (id) setPatientId(id);
    else setPatientId(props?.patientId?.toString() || "");
  };
  const getDoctorId = () => {
    const docId = window.localStorage.getItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`
    );
    setDoctorId(docId || "");
  };

  useMemo(() => {
    getPatientId();
    getDoctorId();
  }, []);
  const handleMyFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleOpenModal = (e: any) => {
    setOpenModal(!openModal);
  };
  const handleFileUpload = async (e: any) => {
    e.preventDefault();
    if (selectedFile?.size > 1024 * 1024) {
      // Check if the file size is greater than 1 MB (you can adjust the size as needed)
      setMsgSize(true);
    } else {
      setIsLoadinge(true);
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("consultationId", item?.id);
        formData.append("name", fileName);
        formData.append("type", type);
        formData.append("userId", item?.doctor?.user?.id);
        formData.append("specialityId", item?.doctor?.speciality?.id);
        formData.append("commentaire", commentaire);
        try {
          sendFileByConsultationId(formData).then((res) => {
            if (res?.status === 201) {
              setIsLoadinge(false);
              setOpenModal(false);
              toast.success("le document a bien enregistrer");
              getAttachmentById(item?.id).then((res) =>
                setFileOverView(res?.data?.results)
              );
            }
          });
        } catch (error) {
          if (error) {
            alert("Le document et large");
          }
          console.error("Error uploading file and other data:", error);
        }
      }
    }
  };

  console.log(item);

  return page === 1 ? (
    <div className="w-full h-full ">
      <div>
        <div className="py-2 flex  items-center justify-between">
          <div className=" w-full  flex justify-end items-center gap-2">
            <Filter />
            <span>Filter</span>
            <Select
              defaultValue={filterValue === "all"}
              className="w-72 "
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#DADADA",
                  primary: "#1AA5C0",
                },
              })}
              options={[
                { value: "all", label: "Tous les compte rendus" },
                { value: "myConsultations", label: "Mes compte rendus" },
                ...specialityOptions,
              ]}
              onChange={(e: any) => {
                setFilterValue(e.value);
              }}
            />
          </div>
        </div>
        <div className=" h-full  flex gap-x-7 mt-2  ">
          <div className="left w-[65%] flex flex-col gap-2  h-[35rem] overflow-y-auto ">
            {
              renduData && renduData.length !== 0 ? (
                renduData?.map((item: any, index: number) => (
                  <Rendu
                    setOverView={setOverview}
                    key={index}
                    setPage={setPage}
                    date={item.date}
                    name={item.name}
                    specialist={item?.specialist}
                    specialistConclusion={item?.specialistConclusion}
                    speciality={item.speciality}
                    conclusion={item.conclusion}
                    item={item.item}
                    setItem={setItem}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center">
                  <p className="text-xl text-[#50799E]">
                    Aucune documentation médicale n'existe pour ce patient{" "}
                  </p>
                </div>
              )
              // <Empty text="Aucune resultat" />
            }
          </div>
          <div className="right p-5  flex-1 border-2 rounded-md  flex-col flex h-[35rem] gap-2 overflow-y-auto">
            <div className=" text-secondary text-[30.91px]">
              Traitements en cours
            </div>
            <div className="w-full h-full flex-col gap-2 flex">
              {traitement &&
                traitement?.map((item: any, index: number) => (
                  <Traitement
                    key={index}
                    medicament={item.medication.name}
                    start={moment(item.startTime).format("DD/MM/YYYY")}
                    end={
                      item.endTime
                        ? moment(item.endTime).format("DD/MM/YYYY")
                        : "À vie"
                    }
                    status={"en cours"}
                    instruction={item.note}
                    info={item.route}
                  />
                ))}
              {traitement && traitement.length == 0
                ? "Aucun traitement en cours pour l'instant"
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="h-screen  overflow-y-scroll pb-60">
      {overview ? (
        <div className="flex justify-start items-start pt-6 gap-6">
          <div className="flex justify-center items-center gap-4">
            <Back
              width={30}
              className="cursor-pointer"
              onClick={() => {
                setPage(1);
                setOverview(false);
                setEditorState(EditorState.createEmpty());

                setMedications([]);
                setFiles([]);
                setFormDataArray([]);
                setMedicationOverview([]);
              }}
            />
            <span className="text-primary text-[16px]  underline">Retour</span>
            {/* {JSON.stringify(item)} */}
            <Chip
              value={item?.createdAt?.substring(0, 10)}
              style={{
                fontSize: "16px",
                borderRadius: "100px",
                background: "rgba(31, 131, 131, 0.1)",
                color: "#1AA5C0",
                fontWeight: "normal",
              }}
            />
          </div>
          <div className="flex flex-col">
            <span className=" text-xl   font-medium text-[#1AA5C0]">
              {item?.doctor?.user.firstName + " " + item?.doctor?.user.lastName}
            </span>
            <span className="text-[16px] text-[#5E5E5E] font-extralight">
              {item?.doctor?.speciality.name}
            </span>
          </div>
        </div>
      ) : null}

      {/* container  */}
      <div className="mt-5">
        <div className="flex">
          <span className="text-primary text-lg font-bold mr-2">Identifiant de consultation:</span><span> #{item.id}</span>
          </div>
        {/* motifs de consultation */}
        {item?.motifComplaint && item.motifComplaint.length > 0 && (
          <div className="flex p-1">
            <h1 className="text-primary text-lg font-bold mr-2">
              Motifs de consultation:{" "}
            </h1>
            {item.motifComplaint.map((motif: string, index: number) => (
              <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
                {motif}
              </span>
            ))}
          </div>
        )}
        {/* exit mode */}
        {item.exitMode && (
          <div className="flex p-1">
            <h1 className="text-primary text-lg font-bold mr-2">
              Mode de sortie:
            </h1>
            {item?.exitMode == EXIT_MODES.NORMAL && (
              <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 items-center rounded-full dark:bg-green-900 dark:text-green-300">
                Sortie normale
              </span>
            )}
            {item?.exitMode == EXIT_MODES.TRANSFERT && (
              <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 items-center rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                Transfert vers l’hôpital régional
              </span>
            )}
            {item?.exitMode == EXIT_MODES.EMERGENGY && (
              <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full items-center dark:bg-red-900 dark:text-red-300">
                Évacuation urgente
              </span>
            )}
          </div>
        )}
        {/* actes médicaux mode */}
        {item?.medicalActConsultations &&
          item?.medicalActConsultations?.length > 0 && (
            <div className="flex p-1">
              <h1 className="text-primary text-lg font-bold mr-2">
                Les actes médicaux:
              </h1>
              <ul>
                {item.medicalActConsultations?.map((medicalActItem: any) => (
                  <li className="bg-red-100 text-red-800 mt-3 text-xs font-medium mr-2 px-2.5 py-0.5 items-center rounded-full dark:bg-red-900 dark:text-red-300">
                    {medicalActItem?.medicalAct?.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        <hr />
        {/* second page */}
        <div className="flex  p-1 mt-5">
          <div className="  flex w-full gap-5">
            <div className="left  w-[70%] flex flex-col ">
              {/* compte rendu generaliste */}
              <span className="text-primary text-lg font-bold ">
                Compte-rendu
              </span>
              <div className="flex flex-col gap-4  h-full">
                {item?.conclusion && (
                  <TextArea value={item.conclusion} rows={10}></TextArea>
                )}
                {!item.conclusion && (
                  <div
                    className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                    role="alert"
                  >
                    <span className="font-medium">
                      Le médecin géneraliste n'a rédigé aucun compte-rendu pour
                      ce patient.
                    </span>
                  </div>
                )}
                <hr style={{ marginTop: "3rem" }} />
                {/* compte rendu specialiste */}
                <div>
                  <span className=" justify-between">
                    <span className="mr-3 text-primary text-lg font-bold ">
                      {" "}
                      Avis spécialiste
                    </span>

                    {item?.specialist && (
                      <>
                        <span className="bg-green-100 text-green-800 text-xl font-large mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{`${item?.specialist?.user?.firstName} ${item?.specialist?.user?.lastName}`}</span>
                        <span className="bg-red-100 text-red-800 text-xl font-large mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{`${item?.specialist?.speciality?.name}`}</span>
                      </>
                    )}
                  </span>
                </div>

                {item.specialistConclusion && (
                  <TextArea
                    value={item.specialistConclusion}
                    rows={10}
                  ></TextArea>
                )}
                {item.specialistConclusion == null && (
                  <div
                    className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                    role="alert"
                  >
                    <span className="font-medium">
                      Le patient n'a consulté aucun médecin spécialiste lors de
                      cette consultation.
                    </span>
                  </div>
                )}

                {!overview ? (
                  <div
                    style={{
                      border: "1px solid #DADADA",
                      borderRadius: "10.22px",
                    }}
                    className="flex flex-col  flex-1 "
                  >
                    <Formik
                      initialValues={{ medicament: "" }}
                      onSubmit={(values) => {
                        setMedications([...medications, values]);
                      }}
                    >
                      {({ values, handleChange, handleSubmit }: any) => (
                        <form onSubmit={handleSubmit}>
                          <div className="up flex p-4   gap-4">
                            <div className="w-[55%]">
                              <Field name="médicament">
                                {({ field, form }: any) => (
                                  <AsyncSelect
                                    required
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={filterOptions}
                                    onChange={(option: any) =>
                                      form.setFieldValue(field.name, option)
                                    }
                                    isClearable
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="flex-1">
                              <Input
                                label="Date de debut"
                                type="date"
                                onChange={handleChange}
                                name={"startDate"}
                                required
                              />
                            </div>
                            <div className="flex-1">
                              <Input
                                label="Date de fin"
                                onChange={handleChange}
                                type="date"
                                name={"endDate"}
                                required
                              />
                            </div>
                          </div>
                          <div className="up flex p-4  gap-4">
                            <div className="w-[20rem] ">
                              <Field name="oral">
                                {({ field, form }: any) => (
                                  <div>
                                    <Select
                                      required
                                      name="oral"
                                      onChange={(option: any) =>
                                        form.setFieldValue(field.name, option)
                                      }
                                      options={[
                                        { value: "oral", label: "Oral" },
                                        {
                                          value: "Rectal",
                                          label: "rectal",
                                        },
                                        {
                                          value: "Sublinguale",
                                          label: "Sublinguale",
                                        },
                                      ]}
                                    />
                                  </div>
                                )}
                              </Field>
                            </div>

                            <div className="flex-1">
                              <Input
                                required
                                // label="Instructions"

                                name="instruction"
                                onChange={handleChange}
                                label="Ex: 1 comprimé par jour"
                              />
                            </div>
                          </div>
                          <div className="p-4 flex justify-end">
                            <MyButton
                              type="submit"
                              color="bg-[#5d5d5d]"
                              text="Nouveau médicament"
                              className="text-white"
                              rounded={true}
                            ></MyButton>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                ) : null}
                {!overview
                  ? medications.map((medication: any, index: number) => (
                      <>
                        <Medications
                          ix={index}
                          key={index}
                          name={medication?.name}
                          startDate={item?.createdAt}
                          morning={medication?.morning}
                          evening={medication?.evening}
                          noon={medication?.noon}
                          period={medication?.period}
                          endDate={medication?.endDate}
                          status={true}
                          instruction={medication?.oral}
                          info={medication?.instruction}
                        />
                      </>
                    ))
                  : medicationOverview.map((medication: any, index: number) => (
                      <>
                        <Medications
                          ix={index}
                          key={index}
                          name={medication?.name}
                          startDate={item?.createdAt}
                          morning={medication?.morning}
                          evening={medication.evening}
                          noon={medication?.noon}
                          period={medication?.period}
                          endDate={medication?.endDate}
                          status={true}
                          instruction={medication?.oral}
                          info={medication?.instruction}
                        />
                      </>
                    ))}

                <hr style={{ marginTop: "3rem" }} />
                <div>
                  <h3 className="text-primary text-sm font-bold">
                    Analyses Biologiques:
                  </h3>
                  {item?.analyseConsultations &&
                  item?.analyseConsultations.length > 0
                    ? item?.analyseConsultations?.map(
                        (d: any, index: number) => (
                          <div className="max-w-full">
                            <li>{d.analyse.name}</li>{" "}
                            <p className="text-gray-500 line-clamp-3">{`Commentaire : ${d.note}`}</p>
                          </div>
                        )
                      )
                    : "Aucune analyse biologique"}
                </div>
                <hr style={{ marginTop: "1rem" }} />
                <div>
                  <h3 className="text-primary text-sm font-bold">
                    Examens Radiologiques:
                  </h3>
                  {item?.imagingConsultations &&
                  item?.imagingConsultations.length > 0
                    ? item?.imagingConsultations?.map(
                        (d: any, index: number) => (
                          <div className="max-w-full">
                            <li>{d.imaging.name}</li>{" "}
                            <p className="text-gray-500  line-clamp-3">{`Commentaire : ${d.note}`}</p>
                          </div>
                        )
                      )
                    : "Aucun examen radiologique"}
                </div>
                <hr style={{ marginTop: "1rem" }} />
                <h1 className="text-primary text-lg font-bold prose">
                  Diagnostics
                </h1>
                <h3 className="text-primary text-sm font-bold">
                  Diagnostic principal:
                </h3>

                <span>
                  {item?.chiefComplaintConsultations &&
                  item?.chiefComplaintConsultations.filter(
                    (e: any) => e.type === "primary"
                  ).length > 0
                    ? item?.chiefComplaintConsultations.filter(
                        (e: any) => e.type === "primary"
                      )[0]?.chiefComplaint.name
                    : "Pas de diagnostic principal"}
                </span>
                <h3 className="text-primary text-sm font-bold">
                  Diagnostics associés:
                </h3>

                {item?.chiefComplaintConsultations &&
                item?.chiefComplaintConsultations.filter(
                  (e: any) => e.type === "secondary"
                ).length > 0
                  ? item?.chiefComplaintConsultations
                      ?.filter((e: any) => e.type === "secondary")
                      .map((d: any, index: number) => (
                        <li>{d.chiefComplaint.name}</li>
                      ))
                  : "Pas de diagnostics associés"}
              </div>
            </div>

            <div className="left  flex flex-col   flex-1 ">
              <div className="flex justify-between">
                <span className="text-primary text-lg font-bold ">
                  Documents
                </span>

                <button
                  onClick={handleOpenModal}
                  className="bg-primary text-white p-2 rounded-lg mb-2"
                >
                  Ajouter Document
                </button>
              </div>

              <Dialog open={openModal} handler={handleOpenModal}>
                {
                  !isLoadinge ? (
                    <>
                      <DialogHeader className="text-primary">
                        Ajouter Document.
                      </DialogHeader>
                      <DialogBody className="gap-5">
                        <div className="mb-6  mt-5">
                          <label
                            htmlFor="default-input"
                            className="block mb-2 text-sm text-primary font-bold dark:text-white"
                          >
                            Le Nom du document
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            type="text"
                            placeholder="Ajouter le nom du document"
                            value={fileName}
                            onChange={(e: any) => setFileName(e.target.value)}
                          />
                          {!fileName ? (
                            <span className="text-red-600">
                              {" "}
                              Ce champ est Obligatoire
                            </span>
                          ) : null}
                        </div>
                        <label
                          className="block mb-2 text-sm text-primary font-bold dark:text-white"
                          htmlFor="file_input"
                        >
                          Ajouter Document
                        </label>
                        <input
                          type="file"
                          accept=".pdf , .jpg , .jpeg , .doc , .docx"
                          onChange={handleMyFileChange}
                          className="block  w-full text-sm text-white border border-gray-300 rounded-lg cursor-pointer
                   bg-primary dark:text-gray-400 focus:outline-none
                    dark:placeholder-gray-400"
                          id="file_input"
                        />
                        {!selectedFile ? (
                          <span className="text-red-600">
                            {" "}
                            Ce champ est Obligatoire
                          </span>
                        ) : null}
                        {msgSize ? (
                          <span className="text-red-600">
                            La taille du fichier dépasse la limite autorisée (1
                            Mo).
                          </span>
                        ) : null}

                        {/* <div className="flex gap-3 justify-between  h-20">
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-19 p-6 h-10  border-2 border-primary border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-8 h-8 pt-1 text-primary dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            <p className="text-xs text-primary dark:text-gray-400"><span className="font-light">Click pour choisir a documents</span></p>
                          </div>

                          <input id="dropzone-file" type="file" accept=".pdf, .jpg, .png" onChange={handleMyFileChange} className="hidden" />

                        </label>

                      </div> */}
                        <div className="mb-6  mt-5">
                          <label
                            htmlFor="default-input"
                            className="block mb-2 text-sm text-primary font-bold dark:text-white"
                          >
                            Commentaire
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            type="text"
                            placeholder="Ajouter Commentaire"
                            value={commentaire}
                            onChange={(e: any) =>
                              setCommentaire(e.target.value)
                            }
                          />
                        </div>

                        <label
                          htmlFor="countries"
                          className="block mb-2 text-sm text-primary font-bold dark:text-white"
                        >
                          Type
                        </label>
                        <select
                          onChange={(e) => setType(e.target.value)}
                          value={type}
                          id="countries"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option selected>--</option>
                          <option value="Échographie">Échographie</option>
                        </select>
                      </DialogBody>
                      <DialogFooter className="flex justify-between ">
                        <button
                          className="bg-red-500 text-white p-2 w-72 rounded-lg"
                          onClick={handleOpenModal}
                        >
                          Annuler
                        </button>
                        {selectedFile && fileName ? (
                          <button
                            className="bg-primary w-72 text-white p-2 rounded-lg"
                            onClick={handleFileUpload}
                          >
                            Envoyer
                          </button>
                        ) : (
                          <button
                            disabled
                            className="bg-gray-200 cursor-not-allowed w-72 text-white p-2 rounded-lg"
                            onClick={handleFileUpload}
                          >
                            Envoyer
                          </button>
                        )}
                      </DialogFooter>
                    </>
                  ) : (
                    <div className="flex justify-center flex-col items-center">
                      <div className="m-10 h-[10rem] ">
                        <img src={seconde} className=" image" />

                        <img src={first} className="mt-[-8rem] ml-11 z-50" />
                      </div>
                      <p className="text-lg text-primary">Merci de patienter</p>
                    </div>
                  )
                  //  <div className="flex flex-col gap-3 justify-center items-center m-12">
                  //   <Spinner className="h-16 w-16" color="blue" />
                  //    <p className="text-lg text-primary">Merci de patienter</p>
                  // </div>
                }
              </Dialog>

              {!overview ? (
                <div className="  file-input mb-5 ">
                  <Button
                    onClick={handleOpen}
                    className="rounded-full w-full bg-primary"
                  >
                    Ajouter document
                  </Button>
                </div>
              ) : null}
              <div className="flex-1  pt-2 items-center border rounded-[10.22px] border-[#DADADA]">
                {!overview ? (
                  <>
                    <div className="text-xl pl-9 text-secondary font-medium">
                      Ordonnance de medicaments
                    </div>
                    <div className=" mt-2 h-5/6 gap-2 flex flex-col items-center ">
                      {files.map((file: any, index: number) => (
                        <Ordonnance
                          path={file.path}
                          deletable={true}
                          deleteFile={deleteFile}
                          index={index}
                          name={file.name}
                          key={index}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className=" mt-2 h-5/6 gap-2 flex flex-col items-center ">
                    {fileOverView?.map((file: any, index: number) => (
                      <Ordonnance
                        path={file.path}
                        deletable={false}
                        deleteFile={deleteFile}
                        index={index}
                        name={file.name}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end second page */}
        {!overview ? (
          <div className=" py-2 rounded-[10.22px] flex-1 flex  justify-end gap-8">
            <button
              onClick={() => {
                setPage(1);
                setOverview(false);
              }}
              className="px-24 text-lg font-semibold text-[#5E5E5E] rounded-3xl py-2 border-2 border-[#5E5E5E] hover:bg-gray-300"
            >
              Annuler
            </button>
            <button
              onClick={() => {
                setConfirm(true);
              }}
              className="px-24 text-lg font-semibold bg-secondary rounded-3xl py-2 text-white "
            >
              Valider
            </button>
          </div>
        ) : null}
        <Dialog
          open={open}
          handler={handleOpen}
          className="  py-5 px-8"
          size="sm"
        >
          <div className="title text-xl text-secondary mb-4">
            Ajouter document
          </div>

          <div className="inputs gap-6  flex justify-between items-center flex-wrap">
            <div className="input flex-1 mb-6">
              <MyUISelect
                label="Spécialité"
                options={specialityOptions}
                title="s"
                name="specialityId"
                onChange={(e: any) => {
                  setInputData({ ...inputData, specialityId: e });
                }}
              />
            </div>
            <div className="input flex-1 mb-6">
              <MyUISelect
                label="Type de document"
                options={[
                  { label: "ordonnance", value: "ordonnance" },
                  { label: "analyse", value: "analyse" },
                ]}
                title="s"
                name="type"
                onChange={(e: any) => setInputData({ ...inputData, type: e })}
              />
            </div>
            <div className="input flex-1 mb-6">
              <h4 className="mb-2 text-lg text-[#1AA5C0]">Titre document</h4>
              <Input
                label=""
                className="focus:outline-none"
                name="name"
                onChange={(e) =>
                  setInputData({
                    ...inputData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div className="input flex-1 mb-6">
              <h4 className="mb-2 text-lg text-[#1AA5C0] ">Commentaire</h4>
              <Input
                name="comment"
                onChange={(e) =>
                  setInputData({
                    ...inputData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="file-input mb-6">
            <h4 className="mb-2 text-lg text-[#1AA5C0]">Fichier</h4>

            {fileSizeError ? (
              <div className="text-red-500 text-sm">
                Taille do fichier large
              </div>
            ) : null}
            <div className="border rounded-md overflow-hidden h-[3rem] border-dashed border-gray-500 relative flex items-center">
              <input
                type="file"
                multiple={false}
                className="cursor-pointer relative block opacity-0 w-full h-full  z-50"
                onChange={handleFileChange}
                name="file"
              />
              <div className="text-center  absolute top-0 right-0 left-0 m-auto">
                <div className="flex justify-center items-center ">
                  {false ? (
                    <div className="flex justify-between items-center bg-[#EFEFEF] p-2 w-full h-[3rem]">
                      {/* <p> {selectedFile}</p> */}
                      <DeleteSvg
                        width={20}
                        // onClick={() => setSelectedFile("")}
                        className="z-[100] cursor-pointer hover:scale-110 transition-all ease-in-out duration-200"
                      />
                    </div>
                  ) : (
                    <div className="flex justify-center gap-2 items-center  p-2 w-full h-[3rem]">
                      <JoinFileSvg width={20} />
                      <p>Veuillez télécharger votre fichier</p>
                    </div>
                  )}
                  <h4> </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons flex justify-between items-center ">
            <MyButton
              text="Annuler"
              color="bg-[#858585] w-[40%] flext justify-center items-center text-white"
              rounded={false}
              onClick={handleOpen}
            />
            <MyButton
              text="Ajouter"
              color="bg-secondary w-[40%] flext justify-center items-center text-white"
              rounded={false}
              onClick={handleSubmit}
            />
          </div>
        </Dialog>
        <Dialog size="md" open={confirm} handler={handleConfirm}>
          <DialogHeader className=" h-10 text-primary text-md">
            Êtes-vous sûr de vouloir valider ?
          </DialogHeader>
          <DialogBody
            className="py-6 text-[#0F2552] font-semibold text-sm"
            divider
          >
            Après validation, il ne sera plus possible de modifier votre compte
            rendu.
          </DialogBody>
          <DialogFooter className="">
            <Button
              // variant="text"
              color="red"
              className="mr-1 bg-[#858585]"
              onClick={handleConfirm}
            >
              <span>Cancel</span>
            </Button>
            <Button
              className="bg-secondary"
              color="teal"
              onClick={handleConfirmOk}
            >
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        </Dialog>
        {isLoader ? <Loader /> : null}
      </div>

      {/* end container */}
    </div>
  );
};

export default Report;
